import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from 'layout/Layout/Layout';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import SignUpBanner from 'components/SignUpBanner';
import Banner from 'common/Banner';
import './tipsAndTricks.scss';
import ArticleList from 'components/ArticleList';
import { toBoolean } from 'utils/stringUtils/stringUtils';

const TipsAndTricksPage: FC<TipsAndTricksTypes.TipsAndTricksProps> = ({
  data: {
    allUmbracoTipsAndTricks: { nodes: tipsAndTricksNodes },
    allUmbracoArticles: { nodes: umbracoArticlesNodes },
    allUmbracoTipsForYou: { nodes: umbracoTipsForYouNodes },
  },
}) => {
  const {
    langProps: { urls, lang },
    seoProps: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs, ogImage },
    articlesMainBanner: { content, bannerVariant, imageAsBackground, imageAsBackgroundAlt },
    articleListSettings,
    tipsAndTricksVideoBanner: {
      bannerTitle,
      content: tipsAndTricksBannerContent,
      bannerVariant: tipsAndTricksBannerVariant,
      imageAsBackgroundAlt: tipsAndTricksBannerimageAsBackgroundAlt,
      withWrapper,
      withVideo,
    },
  } = tipsAndTricksNodes[0];
  const { url: tipsForYouUrl } = umbracoTipsForYouNodes[0] || '/';

  return (
    <Layout langProps={{ urls, lang }}>
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          ogImage,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <Banner
        {...{
          content,
          bannerVariant,
          imageAsBackground,
          imageAsBackgroundAlt,
        }}
      />
      <ArticleList
        {...{ articleListSettings, umbracoArticlesNodes, loadAllArticlesLink: tipsForYouUrl }}
      />
      <Banner
        {...{
          bannerTitle,
          content: tipsAndTricksBannerContent,
          bannerVariant: tipsAndTricksBannerVariant,
          imageAsBackgroundAlt: tipsAndTricksBannerimageAsBackgroundAlt,
          isWithWrapper: toBoolean(withWrapper),
          withVideo,
        }}
      />
      <SignUpBanner {...{ lang }} />
    </Layout>
  );
};

export const query = graphql`
  query TipsAndTricksQuery(
    $tipsAndTricksId: String
    $parentId: Int
    $sortOrder: [SortOrderEnum]
    $sortName: [UmbracoArticlesFieldsEnum]
    $lang: String
  ) {
    allUmbracoTipsAndTricks(
      filter: { id: { eq: $tipsAndTricksId }, langProps: { lang: { eq: $lang } } }
    ) {
      nodes {
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
          ogImage
        }
        tipsAndTricksVideoBanner {
          bannerTitle
          bannerVariant
          content
          imageAsBackgroundAlt
          withWrapper
          withVideo {
            autoplay
            image {
              fallbackUrl
              fluid {
                base64
                originalImg
                srcSet
              }
              variants {
                alias
                fallbackQuery
                type
                url
              }
            }
            link
            title
          }
        }
        articleListSettings {
          sectionTitle
          loadAllArticlesButtonText
          moreText
          moreAriaLabel
          numberOfArticles
          isLabelsActive
        }
        articlesMainBanner {
          content
          bannerVariant
          imageAsBackgroundAlt
          imageAsBackground {
            fallbackUrl
            fluid {
              srcSet
              originalImg
              base64
            }
            variants {
              url
              type
              fallbackQuery
              alias
            }
          }
        }
      }
    }
    allUmbracoArticles(
      filter: { parentId: { eq: $parentId }, langProps: { lang: { eq: $lang } } }
      sort: { fields: $sortName, order: $sortOrder }
    ) {
      nodes {
        langProps {
          lang
        }
        articleThumbnailImage {
          fallbackUrl
          fluid {
            base64
            originalImg
            srcSet
          }
          variants {
            url
            type
            fallbackQuery
            alias
          }
        }
        articleBannerImageAltText
        url
        title
        teaser
        label
        tags {
          articleTag
          id
        }
      }
    }
    allUmbracoTipsForYou(filter: { langProps: { lang: { eq: $lang } } }) {
      nodes {
        url
      }
    }
  }
`;

export default TipsAndTricksPage;
